import React from 'react';

export const Bar = ({ size }: { size: number }) => (
  <hr
    className="bg-primary"
    style={{
      height: `${size}px`,
      opacity: '1',
    }}
  />
);
