import React, { useEffect, useState } from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { Login } from '../components/area-riservata/Login';
import { FilesList } from '../components/area-riservata/FilesList';
import { useLogin } from '@/hooks/useLogin';
import { Bar } from '@/components/ui/Bar';
import { StaticImage } from 'gatsby-plugin-image';

export default function AreaRiservata() {
  const pageTitle = 'Area Riservata';

  const [shouldRenderLogin, setShouldRenderLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { login, token } = useLogin({
    setIsLoading,
    setIsLoggedIn,
    setError,
  });

  useEffect(() => {
    const checkAuthenticationStatus = async () => {
      if (token) {
        setShouldRenderLogin(false);
        setIsLoggedIn(true);
      } else {
        setIsLoading(false);
      }
    };

    checkAuthenticationStatus();
  }, []);

  const Banner = () => (
    <StaticImage
      src="../images/banners/area-riservata.png"
      alt={pageTitle}
      quality={100}
    />
  );

  return (
    <Layout title={pageTitle} isLogged={isLoggedIn}>
      <Hero title={pageTitle} banner={<Banner />} menuColor="#0b577b" />
      <Bar size={8} />
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-12">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : isLoggedIn ? (
              <FilesList token={token} />
            ) : shouldRenderLogin ? (
              <Login
                login={login}
                username={username}
                password={password}
                setUsername={setUsername}
                setPassword={setPassword}
                error={error}
                setError={setError}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
}
