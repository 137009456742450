import { BASE_URL } from '@/constants/links';
import { useState } from 'react';

interface usePrivateDocsProps {
  token: string;
}

export const usePrivateDocs = ({ token }: usePrivateDocsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [docs, setDocs] = useState<never[]>([]);

  const DOCS_URL = BASE_URL + '/wp-json/wp/v2/private_docs';
  const getDocs = () => {
    setIsLoading(true);
    fetch(DOCS_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setDocs(data))
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };
  return { isLoading, docs, getDocs };
};
