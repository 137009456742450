import React, { useEffect } from 'react';

import { Divider } from '@/components/ui/Divider';
import { StaticImage } from 'gatsby-plugin-image';
import { usePrivateDocs } from '@/hooks/usePrivateDocs';
import { BASE_URL } from '@/constants/links';
import axios from 'axios';
import { IS_BROWSER } from '@/constants';

interface FilesListProps {
  token: string;
}

const decodeHTML = (str: string) => {
  const el = document.createElement('el');
  el.innerHTML = str;
  return el.textContent;
};

export const FilesList: React.FC<FilesListProps> = ({ token }) => {
  const { isLoading, docs, getDocs } = usePrivateDocs({
    token,
  });
  useEffect(() => {
    getDocs();
  }, []);

  const downloadFile = (file: string) => {
    if (IS_BROWSER) {
      const parsedURL = new URL(file);
      const pathname = parsedURL.pathname;
      const parts = pathname.split('/');
      const filename = parts[parts.length - 1];
      const url = BASE_URL + `/wp-json/private-files/v1/get?file=${filename}`;

      axios
        .get(url, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = blobUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(a);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div style={{ maxWidth: '1280px' }} className="container mt-5 mb-5">
      <Divider size={3} />
      {/* <pre>{JSON.stringify(docs, null, 2)}</pre> */}
      <div className="row">
        <div className="col-12 text-primary">
          {docs.length === 0 ? (
            <div className="d-flex justify-content-center">
              {isLoading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <h1 className="h3 font-rubik">Non ci sono file da mostrare</h1>
              )}
            </div>
          ) : (
            <div>
              <h1 className="h3 font-rubik">
                In questa sezione vengono caricati i file relativi a:
              </h1>
              {docs.map((doc: any, index: number) => (
                <div className="mt-5" key={index}>
                  <h5 className="font-rubik text-primary text-underline text-uppercase">
                    {decodeHTML(doc.title.rendered)}
                  </h5>
                  {doc.categories && (
                    <h5 className="font-rubik text-secondary text-uppercase">
                      {doc.categories[0]}
                    </h5>
                  )}
                  {doc?.acf?.files?.map((file: any, i: number) => (
                    <div className="row gx-1" key={i}>
                      <div className="col-auto">
                        <StaticImage
                          className="me-1 mt-2"
                          src="../../images/icons/download.svg"
                          alt="download"
                        />
                        <span
                          className="text-secondary text-underline cursor-pointer"
                          onClick={() => downloadFile(file.private_files)}
                        >
                          {file.titolo}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Divider size={5} />
    </div>
  );
};
