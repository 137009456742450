import { useLogin } from '@/hooks/useLogin';
import React, { useState, FC, useEffect, SetStateAction } from 'react';

interface LoginProps {
  login: (username: string, password: string) => void;
  username: string;
  password: string;
  setUsername: React.Dispatch<SetStateAction<string>>;
  setPassword: React.Dispatch<SetStateAction<string>>;
  error: string;
  setError: React.Dispatch<SetStateAction<string>>;
}

export const Login: FC<LoginProps> = ({
  login,
  username,
  password,
  setUsername,
  setPassword,
  error,
  setError,
}) => {
  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login(username, password);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.name === 'username') {
      setUsername(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  return (
    <div className="mw-500 container border p-0">
      <div className="mh-300 d-flex flex-column bg-primary p-5 mb-5 text-center justify-content-center">
        <h2 className="h3 text-white font-rubik">
          Benvenuti <br /> nell'area riservata al <br /> Comune di Vicenza
        </h2>
      </div>
      <div className="mh-300 d-flex flex-column px-5 pb-5 reserved">
        <div className="d-flex me-3 justify-content-center">
          <h3 className="text-uppercase text-primary">Login</h3>
        </div>
        <form onSubmit={handleLogin}>
          {error && (
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex me-3">
                <p className="mb-0 text-red fw-bold">{error}</p>
              </div>
            </div>
          )}
          <div className="d-flex flex-column mb-3">
            <input
              type="text"
              name="username"
              className="form-control bg-primary text-white"
              placeholder="Username"
              aria-label="Username"
              onChange={handleChange}
              value={username}
              required
            />
            <input
              type="password"
              name="password"
              className="form-control bg-primary text-white"
              placeholder="Password"
              aria-label="Password"
              onChange={handleChange}
              value={password}
              required
            />
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex me-3">
              <input type="checkbox" className="form-check-input me-2" />
              <p className="mb-3 text-primary">Ricordami</p>
            </div>
            {/* <div className="d-flex me-3 text-primary">
              <p className="mb-3">Password dimenticata ?</p>
            </div> */}
          </div>
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex me-3">
              <button type="submit" className="btn btn-primary mb-3">
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
