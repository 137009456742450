import { BASE_URL } from '@/constants/links';
import React, { useEffect, useState } from 'react';

interface LoginProps {
  setIsLoading: (isLoading: boolean) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setError: (error: string) => void;
}

export const useLogin = ({
  setIsLoading,
  setIsLoggedIn,
  setError,
}: LoginProps) => {
  const [token, setToken] = useState('');

  const AUTH_URL = BASE_URL + '/wp-json/jwt-auth/v1/token';
  const VALIDATE_URL = BASE_URL + '/wp-json/jwt-auth/v1/token/validate';

  useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem('vicenzaparcheggi_jwf');
    if (token) {
      setToken(token);
      validate(token);
    }
    setIsLoading(false);
  }, []);

  const login = (username: string, password: string) => {
    fetch(AUTH_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          localStorage.setItem('vicenzaparcheggi_jwf', data.token);
          setToken(data.token);
          setIsLoggedIn(true);
        } else {
          setError('Username o password errati');
        }
      });
  };

  const validate = (token?: string) => {
    setIsLoading(true);
    fetch(VALIDATE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 'jwt_auth_valid_token') {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
          setError('Sessione scaduta, effettua nuovamente il login');
          setToken('');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => setIsLoading(false));
  };

  return { login, validate, token };
};
